*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* button {
  user-select: none;
} */

html {
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  overflow-x: hidden;
  font-family: "DM Sans Regular";
  background-color: #000000;
}
@media only screen and (max-width: 700px) {
  body {
    height: 100%;
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 32px;
  border-radius: 32px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  border-radius: 32px;
}

::-webkit-scrollbar-thumb {
  background-color: #8f4ead;
  border-radius: 32px;
  outline: 1px solid #8f4ead;
}
a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

@font-face {
  font-family: "DM Sans Regular";
  src: url("./fonts/DmSans/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Allroundgothic Medium";
  src: url("./fonts/allroundgothic/allroundgothic-medium.ttf")
    format("truetype");
}
@font-face {
  font-family: "Karla Medium";
  src: url("./fonts/karla/Karla-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "CabinetGrotesk Bold";
  src: url("./fonts/CabinetGrotesk-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "CabinetGrotesk Medium";
  src: url("./fonts/CabinetGrotesk-Medium.ttf") format("truetype");
}
